<template>
  <div class="position-relative table-responsive table-base">
    <b-card>
      <div class="position-relative">
        <h3 class="text-center ">
          L'entete de vos factures
        </h3>
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img
              width="100"
              height="100"
              rounded
              :src="entete_entreprise.length===0? '/cover/cover.png':entete_entreprise "
              fluid
              class="img-fluid rounded"
              alt="profil photo"
              feather-icon
              variant="relief-secondary"
              v-b-modal.modal-login            
              icon="Edit3Icon"           
            />
          </div>


          <feather-icon
            variant="relief-secondary"
            v-b-modal.modal-login
            class="ml-50 dt"
            icon="Edit3Icon"
          />

        </div>
        <b-modal
          id="modal-login"
          cancel-variant="outline-secondary"
          ok-title="Enregistrer"
          cancel-title="Annuler"
          centered
          title="Modifier l'entete de vos factures"
          @ok="updateEntete"
        >
          
            <b-col lg="12">
              <b-form-file
                v-model="entete"
                placeholder="Choisissez un fichier "
                drop-placeholder="Drop file here..."
              />
            </b-col>
            <template #modal-footer>
                <b-button
                :disabled="loading1 === true ? true : false"
                variant="primary"
                @click.stop.prevent="updateEntete"
                >
                <span v-if="loading1 === false">Ajouter</span>
                <b-spinner v-if="loading1 === true" label="Spinning"></b-spinner>
                </b-button>
            </template>
            
        </b-modal>

      </div>
    </b-card>

    <!-- <b-card>
      <b-row>
        <b-col lg="12">
          <h5 class="">Ajouter l'entete à vos factures</h5>
          <b-form-file
            v-model="entete"
            placeholder="Choisissez un fichier "
            drop-placeholder="Drop file here..."
          />
        </b-col>
        <b-col lg="2">
          <b-button
            type="submit"
            :disabled="loading === true ? true : false"
            class="bg-indigo mt-2"
            block
            @click="AddEntete"
          >
            <span v-if="loading === false">Enregistrer</span>
            <b-spinner v-if="loading === true" label="Spinning"></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-card> -->

  </div>
</template>

<script>
import { BFormFile } from "bootstrap-vue";
import axios from "axios";
import URL from "../../request";
import { toast_sucess } from "@/utils/qToast";

export default {
  components: {
    BFormFile,
  },
  data() {
    return {
      file: "",
      entete: "",
      media: "",
      entrepriseInfo: "",
      entete_entreprise: "",
      elements: [],
      loading: false,
      loading1:false,
    };
  },

  mounted() {
    try {
      axios.get(URL.ENTREPRISE_INFO).then((response) => {
        this.entrepriseInfo = response.data[0];
        this.entete_entreprise = response.data[0].entete_entreprise;
        // console.log(this.entrepriseInfo);

        // this.entete_entreprise = response.data[0].entreprise.media.length;
        // this.elements = response.data[0].entreprise.media;
        // const fs = this.elements;
        // this.entete_entreprise = this.elements[fs.length - 1].original_url;
        // // console.log(this.entete_entreprise);
      });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    getFile(e) {
      this.entete = e.target.files;
      console.log(e.target.files);
    },

    AddEntete(e) {
      try {
        this.loading = true;
        e.preventDefault();
        const data = new FormData();
        data.append("entete", this.entete);

        // console.log(this.entete);

        const config = {
          headers: {
            Accept: "application/json",
          },
        };

        axios.post(URL.UPLOAD_ENTETE, data).then((response) => {
          this.loading = false;
          this.dataReturn = response.data;
          const fs = this.dataReturn.entreprise.media
          this.entete_entreprise = dataReturn.data.entete_entreprise;

          // this.entete_entreprise = this.dataReturn.entreprise.media[fs.length - 1].original_url;
          // console.log(this.dataReturn);
          toast_sucess(this, "success", "top-right", "Employé ");
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },



    updateEntete(e) {
      try {
        this.loading1 = true;
        e.preventDefault();
        const data = new FormData();
        data.append("entete", this.entete);

        console.log(this.entete);

        const config = {
          headers: {
            Accept: "application/json",
          },
        };

        axios.post(URL.UPDATE_ENTETE, data).then((response) => {
          this.loading1 = false;
          this.dataReturn = response.data;
          const fs = this.dataReturn.entreprise.media
          this.entete_entreprise = this.dataReturn.entreprise.media[fs.length - 1].original_url;
          this.$bvModal.hide('modal-login');
          toast_sucess(this, "success", "top-right", "entete ");
        });
      } catch (error) {
        this.loading1 = false;
        console.log(error);
      }
    },
    




  },
};
</script>
